import { QueryClient } from "react-query";
import { AuthUnauthorizedError, clearTokens } from "@creditas-lab/auth-sdk";
import { redirectToLogin } from "../utils/redirect";

type OnError = (error: Error, variables?: unknown, context?: unknown) => void;

const handleRetry = (failureCount: number, error: unknown): boolean => {
  if (failureCount >= 2 || error instanceof AuthUnauthorizedError) {
    return false;
  }

  return true;
};

const handleUnauthorizedError = (error: unknown): void => {
  if (error instanceof AuthUnauthorizedError) {
    clearTokens();
    redirectToLogin();
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
      retry: handleRetry,
      onError: handleUnauthorizedError,
    },
  },
});

export { queryClient };
