import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import { subscribe, store } from "@creditas/global-store";
import { queryClient } from "./services/queryClient";
import { startMonitoring } from "./services/monitoring";
import { formBuilderProps } from "./services/formBuilder";
import microfrontendLayout from "./microfrontend-layout.html";
import "./styles";

const data = {
  loaders: {},
  props: {
    queryClient,
    globalState: store,
    customProps: formBuilderProps,
  },
};

const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();

if (process.env.USE_MSW_MOCK === "true") {
  import("./mocks/browser").then(({ worker }) => {
    worker.start({
      onUnhandledRequest: "bypass",
      serviceWorker: {
        url: process.env.MSW_MOCK_URL,
      },
    });
  });
}

startMonitoring();

start();

const cancel = subscribe(store.dashboardLoader, () => {
  const { header, spotlight, discovery } = store.dashboardLoader;

  if (header && spotlight && discovery) {
    document.querySelector("html").classList.remove("loader--active");

    cancel();
  }
});
